import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from 'Utils/ApiEndpoints';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.authData?.token;
            const csrfTokenn = getState().auth?.authData?.csrfTokenn;
            if (csrfTokenn) {
                headers.set("X-CSRF-TOKEN", csrfTokenn);
                headers.set("x-app-source", 'web_call');
            }
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
        // credentials: "include",
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '/'
    }
    console.log("result", result)
    return result;
};

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Category'],
    endpoints: (builder) => ({
        getCategoryData: builder.query({
            query: () => ({
                url: `${apiEndpoints?.categoryGetUrl}`,
                method: 'GET',
            }),
            providesTags: ['Category'],
        }),
        getCategoryDataDetails: builder.query({
            query: (categoryId) => ({
                // url: `${apiEndpoints?.categoryDetailsUrl}?category_id=${categoryId}`,
                method: 'GET',
            }),
            providesTags: ['Category'],
        }),
        updateCategory: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.categoryUpdateUrl,
                method: "POST",
                body: data
            }),
            // invalidatesTags: ['Category'],
        }),
        addCategory: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.categoryAddNewUrl,
                method: "POST",
                body: data
            }),
            // invalidatesTags: ['Category'],
        }),
        deleteCategory: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.categoryDeleteUrl,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ['Category'],
        }),
       
    })
});

export const {
    useGetCategoryDataQuery, useGetCategoryDataDetailsQuery, useUpdateCategoryMutation, useAddCategoryMutation, useDeleteCategoryMutation
} = categoryApi;
