import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { authApi } from "./services/auth";
import authSlice from "./slices/auth/authSlice"
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { commonApi } from "./services/common";
import { userApi } from "./services/user";
import { profileApi } from "./services/profile";
import userSlice from "./slices/user/userSlice";
import commonSlice from "./slices/common/commonSlice";
import { findApi } from "./services/find";
import { categoryApi } from "./services/category";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth',]
};
const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [findApi.reducerPath]: findApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  auth: authSlice,
  common: commonSlice,
  user: userSlice
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const createStore = (options) =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(authApi.middleware, commonApi.middleware, userApi.middleware, profileApi.middleware, findApi.middleware, categoryApi.middleware,),
    ...options
  });

export const store = createStore();
export const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch();
export const useTypedSelector = useSelector;

