export const apiEndpoints = {
    loginUrl:`admin/auth/login`,
    signUrl:`admin/auth/signup`,
    forgetUrl :'admin/auth/forgot_password',
    otpVerifyUrl : "admin/auth/verify_otp",
    resendOtpUrl : "admin/auth/resend_otp",
    changePasswordUrl : "admin/auth/change_password",
    resetPasswordUrl : "admin/auth/reset_password",
    profileUrl : "admin/auth/details",
    commonContentUrl : "common/common_content",
    commonContentUpdateUrl : "admin/common/common_content",
    faqGetUrl :"common/questions", 
    faqCommonUrl : "admin/common/question",
    userGetUrl : "admin/user/list", 
    userDetailsUrl : "admin/user/details", 
    userStatusUrl : "admin/user/status",
    userDashboardUrl : "admin/user/dashboard",
    faqUpdateUrl : "admin/common/updatequestion",
    getProfileData: "admin/auth/details",
    updatePassword: "admin/auth/change_password",
    updateAdmin: "admin/auth/profile",
    getDashboardUrl: "admin/auth/dashboard_count",
    findGetUrl : "admin/user/listallfinds", 
    findUpdateStatusUrl : "admin/user/updatefindstatus", 
    findStatusUrl : "admin/user/status",
    categoryGetUrl : "admin/user/list_category", 
    categoryAddNewUrl : "admin/user/create_category", 
    categoryUpdateUrl : "admin/user/editCategory", 
    categoryDeleteUrl : "admin/user/deleteCategory", 
   
}